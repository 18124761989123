.root {
  display: inline-block;
  vertical-align: top; /* this is for correct alignment when used in table */
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.root_wrap {
  white-space: normal;
}
