.grid {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
    0 0 0 rgba(16, 22, 26, 0);
}

.stickyCellGroup {
  float: left;
  position: sticky;
  overflow: hidden;
}

.stickyCellGroup_position_topLeft {
  z-index: 1;
  top: 0;
  left: 0;
}

.stickyCellGroup_position_topRight {
  top: 0;
}

.stickyCellGroup_position_centerLeft {
  left: 0;
}

.stickyCellGroup_position_bottomLeft {
  clear: left;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.stickyCellGroup_position_bottomRight {
  bottom: 0;
}

.resizeUiRuler {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 3px;
  background-color: var(--pt-intent-primary);
}

.spinnerOverlayParent {
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
}

.spinnerOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  opacity: 0.7;
  width: 100%;
}
