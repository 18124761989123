.baseCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--pt-grid-size);
  overflow: hidden;
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15),
    inset -1px 0 0 rgba(16, 22, 26, 0.15);
  background: #fff;
  white-space: nowrap;
}

.baseCellInner {
  max-height: 100%;
}

.baseCell_boldBorderTop::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 2px solid rgba(92, 112, 128, 0.6);
}

.baseCell_head {
  background: #f5f8fa;
}

.baseCell_isSelectable {
  cursor: cell;
  user-select: none;
}

.baseCell_noPadding {
  padding: 0;
}

.cellEditor {
  z-index: 10;
}

.editableTextCellInputWrapper {
  z-index: 10;
}

.editableTextCellInput {
  margin: -1px;
  border: 0;
  padding: 0 calc(var(--pt-grid-size) + 1px);
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  background: #fff;
  font: inherit;
}

.headCellResizeHandle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  cursor: col-resize;
}

.headCellResizeHandle:hover {
  background-color: var(--pt-intent-primary);
}

.headCellContentWrapper {
  display: flex;
  height: 100%;
}

.headCellContentPrepended {
  flex: 0 0 auto;
}

.headCellContentMain {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}

.headCellSortingButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 calc(0px - var(--pt-grid-size));
  border: 0;
  padding: 0 var(--pt-grid-size);
  height: 100%;
  background: none;
  font: inherit;
  color: inherit;
  text-align: left;
  cursor: pointer;
}

.headCellSortingButton_withPrepend {
  margin-left: 0;
}

.headCellSortingButtonText {
  flex: 1 1 0;
  min-width: 0;
}

.headCellSortingButtonIcon {
  flex: 0 0 auto;
  margin-left: calc(var(--pt-grid-size) / 2);
  color: #5c7080;
}
