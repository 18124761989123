:root {
  --gray3: #8a9ba8;
  --gray5: #bfccd6;
  --light-gray1: #ced9e0;
  --pt-divider-black: rgba(16, 22, 26, 0.15);
  --pt-grid-size: 10px;
  --pt-intent-danger: #db3737;
  --pt-intent-primary: #137cbd;
  --pt-link-color: #106ba3;
  --pt-navbar-height: 50px;
  --pt-text-color: #182026;
  --pt-z-index-overlay: 20;
  --white: #ffffff;
}

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
  height: auto;
}

.bp3-tab-list {
  flex-wrap: wrap;
}
