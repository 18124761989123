.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.children {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: calc(var(--pt-grid-size) * 3);
  min-width: 0;
}

@media (max-width: 768px) {
  .children {
    padding: calc(var(--pt-grid-size));
  }
}
