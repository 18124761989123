.vgrid {
  margin: calc(0px - var(--pt-grid-size)) 0;
}

.vgrid_stretch {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.row {
  padding: var(--pt-grid-size) 0;
}

.row_stretch {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.row_wrap {
  flex-wrap: wrap;
}

.row_stretch:not(.row_containContent) {
  min-height: 0;
}

.row_stickToBottom {
  position: sticky;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.grid {
  margin: 0 calc(0px - var(--pt-grid-size));
  display: flex;
  flex-wrap: wrap;
  --col-count: var(--col-span, 12);
}

.col {
  padding: 0 var(--pt-grid-size);
  flex: 1 1 auto;
  min-width: 0;
}

.col_align_center,
.col_align_end {
  display: flex;
}

.col_align_center {
  justify-content: center;
}

.col_align_end {
  justify-content: flex-end;
}

.col_span_1 {
  flex: 0 0 calc(100% * 1 / var(--col-count));
  --col-span: 1;
}

.col_span_2 {
  flex: 0 0 calc(100% * 2 / var(--col-count));
  --col-span: 2;
}

.col_span_3 {
  flex: 0 0 calc(100% * 3 / var(--col-count));
  --col-span: 3;
}

.col_span_4 {
  flex: 0 0 calc(100% * 4 / var(--col-count));
  --col-span: 4;
}

.col_span_5 {
  flex: 0 0 calc(100% * 5 / var(--col-count));
  --col-span: 5;
}

.col_span_6 {
  flex: 0 0 calc(100% * 6 / var(--col-count));
  --col-span: 6;
}

.col_span_7 {
  flex: 0 0 calc(100% * 7 / var(--col-count));
  --col-span: 7;
}

.col_span_8 {
  flex: 0 0 calc(100% * 8 / var(--col-count));
  --col-span: 8;
}

.col_span_9 {
  flex: 0 0 calc(100% * 9 / var(--col-count));
  --col-span: 9;
}

.col_span_10 {
  flex: 0 0 calc(100% * 10 / var(--col-count));
  --col-span: 10;
}

.col_span_11 {
  flex: 0 0 calc(100% * 11 / var(--col-count));
  --col-span: 11;
}

.col_span_12 {
  flex: 0 0 calc(100% * 12 / var(--col-count));
  --col-span: 12;
}
