.cardHeaderButton {
  float: right;
  margin-left: var(--pt-grid-size);
}

.cardHeaderTitle {
  overflow-wrap: anywhere;
}

.cardHeaderTitle::before,
.cardHeaderTitle::after {
  content: '';
  display: table;
}

.cardHeaderTitle::after {
  clear: both;
}

.cardTable {
  width: 100%;
}
