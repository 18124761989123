.root {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.card {
  flex: 1 0 auto;
  max-width: 25em;
}
